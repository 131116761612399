<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="">
          <b-form-group label-cols="4" label-cols-lg="2" label="Tanggal">
            <v-select v-model="filter.sessionId" value="id" :options="sessions" :reduce="(item) => item.id"
              placeholder="Sesi" label="session" @input="getSummary" />
          </b-form-group>

          <b-form-group label-cols="4" label-cols-lg="2" label="Blok/Matakuliah">
            <b-form-input v-model="lecturer.subject" disabled />
          </b-form-group>

          <b-form-group label-cols="4" label-cols-lg="2" label="NIP">
            <b-form-input v-model="lecturer.nip" disabled />
          </b-form-group>

          <b-form-group label-cols="4" label-cols-lg="2" label="Nama Dosen">
            <b-form-input v-model="lecturer.name" disabled />
          </b-form-group>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-if="filter.sessionId !== null" type="reset"
            variant="outline-secondary" class="float-right" @click="print" :disabled="statusPrint">
            Print
            <b-spinner v-if="statusPrint" small />
          </b-button>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-if="filter.sessionId !== null" type="reset"
            variant="outline-secondary" class="float-right mr-1" @click="exportExcel" :disabled="starusExport">
            Export Excel
            <b-spinner v-if="starusExport" small />
          </b-button>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
            class="float-right mr-1" @click="$router.go(-1)">
            Kembali
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-table responsive :fields="columns" :items="rows">
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>

      <b-list-group>
        <b-list-group-item class="flex-column align-items-start" v-for="essay in essays" :key="essay.no"
          :active="essay.no == 1 ? true : false">
          <div class="d-flex w-100 justify-content-between">
            <h5 :class="essay.no == 1 ? 'text-white' : null">
              {{ `#${essay.no}` }}
            </h5>
            <small class="text-secondary">{{ essay.createdat }}</small>
          </div>
          <b-card-text>
            {{ essay.questionname }}
          </b-card-text>
          <small class="text-secondary">{{ essay.answer }}</small>
        </b-list-group-item>
      </b-list-group>

      <!-- <b-card style="background-color: #f0f2f5; margin-top: 38px">
        <h5 class="font-bold">Keterangan Skor</h5>
        <h5 class="font-bold">1 : Sangat Kurang (SK)</h5>
        <h5 class="font-bold">2 : Kurang (K)</h5>
        <h5 class="font-bold">3 : Cukup (C)</h5>
        <h5 class="font-bold">4 : Baik (B)</h5>
        <h5 class="font-bold">5 : Sangat Baik (SB)</h5>
      </b-card> -->

      <b-card style="background-color: #f0f2f5; margin-top: 38px">
        <h6 class="font-bold" style="color: black">Keterangan Skor</h6>
        <h6 style="color: black">1 : Tidak Puas (TP)</h6>
        <h6 style="color: black">2 : Kurang Puas (KP)</h6>
        <h6 style="color: black">3 : Cukup Puas (CP)</h6>
        <h6 style="color: black">4 : Puas (P)</h6>
        <h6 style="color: black">5 : Sangat Puas (SP)</h6>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
  BTable,
  BListGroup,
  BListGroupItem,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FileDownload from "js-file-download";

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    BTable,
    BListGroup,
    BListGroupItem,
    BButton,
    vSelect,
    ToastificationContent,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusPrint: false,
      statusExport: false,
      lecturer: {
        nip: null,
        name: null,
        subject: null,
      },
      sessionApi: `questionnaire-session/get-questionnaire-session/${this.$route.params.blockId}/${this.$route.params.lecturerId}`,
      api: "questionnaire-session/detail-questionnaire-session",
      printApi: "questionnaire-session/print",
      exportApi: "questionnaire-session/export",
      filter: {
        lecturer: this.$route.params.lecturerId,
        block: this.$route.params.blockId,
        sessionId: null,
        sessionCode: null,
      },
      sessions: [],
      rows: [],
      essays: [],
      columns: [
        {
          label: "No",
          key: "index",
        },
        {
          label: "Aspek Penilaian",
          key: "questionname",
        },
        {
          label: "Skor",
          key: "answer",
        },
      ],
    };
  },
  async mounted() {
    const _ = this;
    const lecturer = _.$store.getters["summarySession/GET_USER"];
    if (lecturer.nip === null) {
      _.$router.go(-1);
    }
    _.lecturer.nip = lecturer.nip;
    _.lecturer.name = lecturer.name;
    _.lecturer.subject = lecturer.course;
    _.sessions = await _.getSessions();
  },
  watch: {
    "filter.sessionId"(newSessionId, oldSessionId) {
      let session = this.sessions.filter((session) => {
        return session.id == newSessionId;
      });

      this.filter.sessionCode = session[0].sessionCode;
    },
  },
  methods: {
    async getSummary() {
      let data;
      const _ = this;
      await _.$axios.get(`${_.api}/${_.filter.sessionId}`).then((res) => {
        data = res.data.data;
      });
      _.rows = data?.poins;
      _.essays = data?.essays.map((value, index) => {
        value.createdat = this.$dayjs(value.createdat).format("DD-MM-YYYY");
        return { ...value, no: index + 1 };
      });
    },
    async getSessions() {
      const _ = this;
      let data;
      await _.$axios.get(_.sessionApi).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    async print() {
      const _ = this;
      _.statusPrint = true;
      await _.$axios
        .get(`${_.printApi}/${_.filter.sessionCode}`, {
          responseType: "blob",
        })
        .then((res) => {
          _.statusPrint = false;
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        })
        .catch((err) => {
          _.statusPrint = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Gagal Print Kuesioner",
              variant: "danger",
            },
          });
        });
    },
    async exportExcel() {
      const _ = this;
      _.statusExport = true;
      await _.$axios
        .get(`${_.exportApi}/${_.filter.sessionId}`, {
          responseType: "blob",
        })
        .then((res) => {
          _.statusExport = false;
          FileDownload(res.data, 'export-kuesioner-sesi.xlsx');
        })
        .catch((err) => {
          _.statusExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Gagal Export Kuesioner",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.font-bold {
  font-weight: 900;
}
</style>
